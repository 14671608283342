









import { Match } from '@/models/case-maintenance.d';
import {
  Component, Prop, Vue
} from 'vue-property-decorator';

import BookingDetailCargoPropertyDefault from './booking-detail-cargo-property-default.vue';
import BookingDetailCargoPropertyFlashpoint from './booking-detail-cargo-property-flashpoint.vue';
import BookingDetailCargoPropertyPackaging from './booking-detail-cargo-property-packaging.vue';
import BookingDetailCargoPropertySubsidiaryHazard from './booking-detail-cargo-property-subsidiary-hazard.vue';

@Component
export default class BookingDetailCargoProperty extends Vue {
  @Prop({ required: true }) propertyName!: string
  @Prop({ required: true }) value: any;
    @Prop({ required: true }) matches!: Match[];

  get type() {
    if (this.propertyName.startsWith("flashpoint")) return "flashpoint";
    if (this.propertyName.startsWith("packaging")) return "packaging";
    if (this.propertyName.startsWith("subsidiary-hazard")) return "subsidiary-hazard";
    return "";
  }

  get component() {
    if (this.type === "flashpoint") return BookingDetailCargoPropertyFlashpoint;
    if (this.type === "packaging") return BookingDetailCargoPropertyPackaging;
    if (this.type === "subsidiary-hazard") return BookingDetailCargoPropertySubsidiaryHazard;
    return BookingDetailCargoPropertyDefault;
  }
}

