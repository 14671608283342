






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';
import ContainerSummary from './container-summary.vue';
import CaseHit from './case-hit.vue';

@Component({
  components: {
    ContainerSummary,
    CaseHit,
  }
})
export default class CaseHits extends Vue {
  @Prop({ required: true }) model!: CaseView;
  @Prop({ required: true }) booking!: any;

  showCurrentHeader() {
    return this.currentHits?.length && this.pastHits?.length
  }

  get currentHits() {
    return this.model?.hits?.filter(hit => hit.isCurrent);
  }

  showPastHeader() {
    return this.pastHits?.length
  }

  get pastHits() {
    return this.model?.hits?.filter(hit => !hit.isCurrent);
  }
}
