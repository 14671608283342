

 function getId(indexString: string): string {
    const quotedIndexEtc = indexString.split("'");
    const id = quotedIndexEtc[1];
    return id;
  }

export function getSubPathFromField(path: string | null): string {
    if (path == null) return "";
    const splitPath = path.split("]");
    if (splitPath.length == 1) {
      return "";
    }
    const endsInIndex = splitPath[1].split("[");
    const indexJSON = endsInIndex[1].split("[");
    if (indexJSON[0].indexOf("'") == -1) {
      return indexJSON[0];
    } else {
      return getId(indexJSON[0]);
    }
  }

