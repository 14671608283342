




import { HitView } from "@/models/case-maintenance";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class BookingDetailPartiesSubtitle extends Vue {
  @Prop({ required: true }) prop?: string;
  @Prop({ required: true }) propValue?: string;
  @Prop({ required: true }) hits!: HitView[];
}
