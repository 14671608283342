import { render, staticRenderFns } from "./case-hits.vue?vue&type=template&id=11376df6&"
import script from "./case-hits.vue?vue&type=script&lang=ts&"
export * from "./case-hits.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VExpansionPanel,VExpansionPanelHeader,VExpansionPanels,VSpacer})
