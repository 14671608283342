






















import { Component, Prop, Vue } from 'vue-property-decorator';
import CargoProperties from './booking-detail-cargo-properties.vue';
import CargoDescriptions from './booking-detail-cargo-descriptions.vue';
import { Match } from '@/models/case-maintenance.d';

@Component({
  components:{
    CargoProperties,
    CargoDescriptions
  }
})
export default class BookingDetailCargoComponent extends Vue {
  @Prop({ required: true }) cargo!: any;
  @Prop({ required: true }) matches!: Match[];
  @Prop({ required: true }) path!: string;

  isDangerousGoods() {
    return 'un-number' in this.cargo;
  }
}
