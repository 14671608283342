export default function getSingleQueryParam(value: string|(string|null)[], defaultValue: string): string {
    if(Array.isArray(value)){
      if (value.length == 0) {
        return defaultValue;
      } else if (value[0]) {
        return value[0];
      } else {
        return defaultValue;
      }
    } else {
      return value;
    }
  }