import EcSnackBar from '@/components/common/ec-snackbar.vue'
import AppState from '@/store/modules/app-module';
import { getModule } from 'vuex-module-decorators';

const appState = getModule(AppState);

export function setProgressMessage(message: string) {
    appState.setSnackbarOptions(EcSnackBar.makeProgressOptions(message));
}

export function setSuccessMessage(message: string) {
    appState.setSnackbarOptions(EcSnackBar.makeSuccessfulOptions(message));
}

export function setUnsuccesfulMessage(message: string) {
    appState.setSnackbarOptions(EcSnackBar.makeUnsuccessfulOptions(message));
}

export function setDefaultMessage() {
    appState.setSnackbarOptions(EcSnackBar.makeDefaultOptions());
}
