












import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import { HitView } from '@/models/case-maintenance';
import { distinct } from '@/helpers/distinct';

@Component
export default class ContainerSummaryTable extends Vue {
  @Prop({ required: true }) hit!: HitView;

  get containers() {

    return this.hit.matches
      .flatMap(x => x.location)
      .filter(x => x.key == 'cargo-transport-units')
      .map(x => x.indexDescription)
      .filter(distinct)
      .sort();
  }
}
