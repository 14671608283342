



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BookingDetail from "./booking-detail.vue";
import BookingHeader from "./booking-header.vue";
import { HitView } from "@/models/case-maintenance";
import getSingleQueryParam from "@/helpers/queryParam";

type BookingNode = {
  id: string;
  name: string;
  children?: BookingNode[];
};

@Component({
  components: {
    BookingDetail,
    BookingHeader
  },
  inheritAttrs: false
})
export default class Booking extends Vue {
  @Prop({ required: true }) booking!: any;
  @Prop({ required: true }) hits!: HitView[] | null;
  @Prop({ required: true }) highlightSelected!: boolean;
  @Prop() bookingNode!: string[];

  active: string[] = [];
  open: string[] = [];
  activeTab = "";
  reservedPaths = ["$.sailings", "$.parties"];

  treeViewClicked() {
    if (this.$route.query.tabToSelect != "0") {
      const prevPath = getSingleQueryParam(this.$route.query.path, "");
      this.$router.replace({ query: { path: prevPath, tabToSelect: "0" } });
    }
  }

  treeViewUpdated(event: any[]) {
    if (event.length > 0) {
      this.$nextTick(() => {
        const prevPath = getSingleQueryParam(this.$route.query.path, "");
        if (event.length > 0 && prevPath !== event[0]) {
          const prevTabToSelect = this.$route.query.tabToSelect ?? "0";
          this.$router.replace({ query: { path: event[0] ?? null, tabToSelect: prevTabToSelect } });
        }
      });
    }
  }

  bookingNodes: BookingNode[] = [];

  get currentHits() {
    if (!this.hits) return [];

    const current = this.hits.filter(x => x.isCurrent);

    if (this.highlightSelected) {
      const selectedMatchesWithHits = current.map(hit => {
        return { ...hit, matches: hit.matches.filter(match => match.singleHighlight) };
      });

      return selectedMatchesWithHits;
    }

    return current;
  }

  @Watch("$route", { immediate: true })
  pathChanged() {
    this.loadTreeview();
  }

  loadTreeview() {
    this.active = [];
    this.open = [];

    const path = getSingleQueryParam(this.$route.query.path, "");
    const basePath = this.getBasePath(path);
    const toOpen = this.getOpenPath(basePath);
    this.open.push(toOpen);
    this.active.push(basePath);
  }

  getOpenPath(path: string): string {
    const split = path.split("[");
    return split[0];
  }

  getBasePath(path: string | null): string {
    if (path == null) return "";
    if (this.reservedPaths.includes(path)) {
      return path;
    }
    const split = path.split("]");
    return split[0] + "]";
  }

  getSubPath(path: string | null): string {
    if (path == null) return "";
    const splitPath = path.split("]");

    if (splitPath[1] !== "") {
      const endsInIndex = splitPath[1].split("[");
      const indexJSON = endsInIndex[1].split("[");
      if (indexJSON[0].indexOf("'") == -1) {
        return indexJSON[0];
      } else {
        return this.getId(indexJSON[0]);
      }
    } else {
      return "[0]";
    }
  }

  getId(indexString: string): string {
    const quotedIndexEtc = indexString.split("'");
    const id = quotedIndexEtc[1];
    return id;
  }

  removeArrayIndex(path: string): string {
    return path.replace(/\[.\]/, "");
  }

  @Watch("booking", { immediate: true })
  bookingChanged() {
    if (!this.booking || Object.keys(this.booking).length == 0) {
      this.bookingNodes = [
        {
          id: "1",
          name: "No Booking"
        }
      ];

      return;
    }

    const extraRootNodes = Object.keys(this.booking)
      .filter(key => ["sailings", "cargo-transport-units", "parties"].indexOf(key) == -1)
      .filter(key => Array.isArray(this.booking[key]));

    this.bookingNodes = extraRootNodes
      .map(key => this.buildNode(this.booking[key], key, `$.${key}`))
      .concat([
        this.buildNode(
          this.booking["cargo-transport-units"],
          "Containers",
          "$.cargo-transport-units",
          2
        ),
        this.buildNode(this.booking["sailings"], "Sailings", "$.sailings", 1),
        this.buildNode(this.booking["parties"], "Parties", "$.parties", 1)
      ]);
  }

  buildNode(value: any, name: string, id: string, depth?: number) {
    if (typeof value === "object") {
      return {
        id: id,
        name: Vue.filter("kebabToPascal")(name),
        children: this.buildChildren(value, id, depth && depth - 1)
      };
    }

    return {
      id: id,
      name: `${Vue.filter("kebabToPascal")(name)} = ${value}`
    };
  }

  buildChildren(value: any, parentId: string, depth?: number) {
    if (depth === 0) return [];

    if (Array.isArray(value)) {
      const props: any[] = [];
      for (let i = 0; i < value.length; i++) {
        const indexer = this.buildArrayIndex(i, value);
        let name = `${i + 1}`;
        if (value[i].$id) {
          name = `${name}: ${value[i].$id}`;
        }

        props.push(this.buildNode(value[i], name, `${parentId}[${indexer}]`, depth));
      }
      return props;
    }

    if (typeof value === "object") {
      const props: any[] = [];
      for (const prop of Object.keys(value)) {
        props.push(this.buildNode(value[prop], prop, `${parentId}.${prop}`, depth));
      }
      return props;
    }

    return [];
  }

  buildArrayIndex(index: number, value: any[]) {
    if ("$id" in value[index]) {
      const id = value[index].$id;

      return `?(@.$id == '${id}')`;
    }

    return index;
  }
}
