


















import { Inject, Component, Prop, Vue } from 'vue-property-decorator';

import { CaseView } from '@/models/case-maintenance';
import { CaseEmail } from '@/models/case-maintenance.d';
import UserControl from '@/components/user.vue';
import CaseHistoryItem from '@/components/case-history/case-history-item.vue';
import CaseEmailResend from '@/components/case/case-email-resend.vue';
import EmailService from '@/services/email-service';

@Component({
  components: {
    User: UserControl,
    CaseHistoryItem,
    CaseEmailResend
  }
})
export default class CaseHistoryAssignment extends Vue {
  @Prop({ required: true }) data!: CaseEmail;
  @Prop({ required: true }) model!: CaseView;
  @Inject() EmailService!: EmailService;

  get recipients() {
    return this.data.recipients.map(x => x.address).join("; ")
  }

  resendEmail(recipientEmail: string, subject: string, message: string) {
    return this.EmailService.resendCaseEmail(
      this.model['case-id'],
      recipientEmail,
      subject,
      message
    ).then(() =>
      {
        this.$emit('refresh-history');
      });
  }
}
