







































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { CaseEmail } from '@/models/case-maintenance.d';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from '@/components/common/ec-snackbar.vue';

@Component({
  components: {
    EcSnackBar
  }
})
export default class CaseEmailResend extends Vue {
  @Prop( { required: true }) email!: CaseEmail;
  @Prop({ required: true }) resendEmail!: (recipientEmail: string, subject: string, message: string) => Promise<any>;

  resendEmailDialog = false;
  recipientEmail = "";
  subject = "";
  message = "";
  error = "";

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  @Ref() dialogForm!: HTMLFormElement;

  openResendEmailDialog(){
    // Only one email recipient is allowed by the controllers.
    this.recipientEmail = this.email.recipients[0].address;
    this.message = this.email.body;
    this.subject = this.email.subject;
    this.resendEmailDialog = true;
  }

  cancel() {
    this.reset();
  }

  resending = false;

  async resend() {
    if (!this.dialogForm.validate()) return;
    this.resending = true;

    try {
      await this.resendEmail(this.recipientEmail, this.subject, this.message);
      this.reset();
    } catch (e) {
      this.error = "Something went wrong resending the email."
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(this.error);
    } finally {
      this.resending = false;
    }
  }

  reset() {
    this.resendEmailDialog = false;
    this.recipientEmail = "";
    this.message = "";
    this.error = "";
    this.dialogForm.reset();
  }
}
