















import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';

import { CaseView } from '@/models/case-maintenance';
import CaseHistoryAssignment from './case-history-assignment.vue';
import CaseHistoryNote from './case-history-note.vue';
import CaseHistoryStatus from './case-history-status.vue';
import CaseHistoryScreening from './case-history-screening.vue';
import CaseHistoryEmail from './case-history-email.vue';

type DatePropertyNames<T> = {
  [P in keyof T]: T[P] extends Date ? P : never
}[keyof T];

type CaseEvent = {
  component: typeof Vue;
  data: any;
  date: Date;
}

@Component
export default class CaseHistory extends Vue {
  @Prop({ required: true }) model!: CaseView

  events: CaseEvent[] = [];

  @Watch('model', { immediate: true })
  modelUpdated() {
    const events =
      this.model['screen-results']
        .map(x => this.buildEvent(CaseHistoryScreening, x, 'screening-date'))
      .concat(this.model['case-notes']
        .map(x => this.buildEvent(CaseHistoryNote, x, 'created-at')))
      .concat(this.model['status-history']
        .map(x => this.buildEvent(CaseHistoryStatus, x, 'created-at')))
      .concat(this.model['assignment-history']
        .map(x => this.buildEvent(CaseHistoryAssignment, x, 'created-at')))
      .concat(this.model['emails']
        .map(x => this.buildEvent(CaseHistoryEmail, x, 'created-at')))

    events.sort((a, b) => a.date < b.date ? 1 : -1);

    this.events = events;
  }

  buildEvent<T, P extends DatePropertyNames<T>>(component: typeof Vue, data: T, key: P): CaseEvent {
    return {
      component,
      data: data as any,
      date: data[key]
    }
  }
}
