




import { HitView } from "@/models/case-maintenance";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class BookingDetailPartiesTitle extends Vue {
  @Prop({ required: true }) party?: string;
  @Prop({ required: true }) hits!: HitView[];
}
