

































import { Vue } from "vue-property-decorator";
import { HitView, MatchView } from "@/models/case-maintenance";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { getSubPathFromField } from "../helper/case-hit-nagivation";

@Component({
  components: {}
})
export default class BookingHitNavigationComponent extends Vue {
  @Prop({ required: true }) hits!: HitView[];

  get isFirst() {
    return this.selected < 1;
  }

  get isLast() {
    return this.selected === this.matches.length - 1;
  }

  get matches() {
    return this.hits.map(x => x.matches).flat();
  }

  get selected() {
    const singleHighlighMatch = this.matches.find(x => x.singleHighlight);
    return singleHighlighMatch ? this.matches.indexOf(singleHighlighMatch) : -1;
  }

  getSelectedHit() {
    return this.hits?.find(
      hit => hit.matches.filter(match => match.singleHighlight).length > 0
    ) as HitView;
  }

  selectedRuleCondition = "Not Selected";

  get displayText() {
    const selected = this.getSelectedHit();
    if (!selected) {
      this.selectedRuleCondition = "Not Selected";
      return "Showing All Hits";
    }

    const rule = selected.rule;
    this.selectedRuleCondition = selected.rule.condition;

    const match = selected.matches.filter(x => x.singleHighlight)[0].text;

    const textToDisplay = `Rule: ${rule.name} Match: ${match}`;
    return textToDisplay.length > 100 ? `${textToDisplay.substring(0, 100)}...` : textToDisplay;
  }

  emitSelectedMatch(matchView: MatchView) {
    this.$emit("selected-match", matchView);
  }

  emitDeselect() {
    this.$emit("deselect-match");
  }

  goToNext(value: number) {
    const next = this.matches[this.selected + value];

    if (!next) {
      this.emitDeselect();
      this.$router.replace({ query: {} }).catch(() => {
        return true;
      });
      return;
    }
    this.navToHitInBooking(next);
  }

  navToHitInBooking(matchView: MatchView) {
    this.emitSelectedMatch(matchView);
    const cargoIndex = getSubPathFromField(matchView.field);
    this.$router
      .replace({ query: { path: matchView.field, tabToSelect: cargoIndex } })
      .catch(() => {
        return true;
      });
  }
}
