import { CaseDetail } from '@/views/Case.d';
import { CaseView } from "@/models/case-maintenance";
import { distinct } from "@/helpers/distinct";
import config from "@/config";

function getDetail(key: string, caseModel: CaseView) {
  if (!caseModel || !caseModel["case-details"]) return;

  return caseModel["case-details"]
    .filter(x => x.key == key)
    .map(x => x.value)
    .filter(distinct)
    .sort()
    .join(", ");
}

export function getCaseDetails(caseModel: CaseView): CaseDetail[] {
  return config.displayCaseDetails
  .filter(x => x.type !== "link")
  .map(x => ({ name: x.name, value: getDetail(x.key, caseModel) }))
  .filter(x => x.value);
}

export const caseTabs = [
  { id: 0, name: "Hits" },
  { id: 1, name: "Booking" },
  { id: 2, name: "Notes" },
  { id: 3, name: "History" }
];