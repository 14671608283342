


















import {
  Vue, Component, Prop
} from 'vue-property-decorator';
import Self from './recursive-details.vue';

@Component({
  components: {
    RecursiveDetails: Self
  }
})
export default class RecursiveDetails extends Vue {
  @Prop({ required: true }) stage: any;

  get properties() {
    if (!this.stage) return [];

    return Object.keys(this.stage)
      .filter(prop => prop[0] !== '$')
      .filter(prop => this.stage[prop] !== undefined);
  }
}

