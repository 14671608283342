


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView } from '@/models/case-maintenance';
import config from '@/config';

@Component
export default class CaseLinksComponent extends Vue {
  @Prop({ required: true }) model!: CaseView;

  get links() {
    const links = config.displayCaseDetails
      .filter(detail => detail.type === 'link')
      .map(detail => {
        const details = this.model["case-details"] && this.model["case-details"]
          .filter(x => x.key === detail.key)

        const hasLink = details?.length > 0;
        const link = hasLink ? details?.[0] : null;
        const target = hasLink? detail.target : null

        return {
          href: link?.value,
          disabled: !hasLink,
          text: detail.name,
          icon: 'mdi-link-variant',
          target: target
        };
      });

    return links;
  }
}
