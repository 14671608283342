














import { Vue, Component, Prop } from "vue-property-decorator";

import BookingDetailContainer from "./booking-detail-container.vue";
import { HitView } from "@/models/case-maintenance";

@Component({
  components: {
    BookingDetailContainer
  }
})
export default class BookingDetailContainers extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string; // "$.ctus[?(@.$id == 'CTNR0000123')]" // $.ctus[0]
  @Prop({ required: true }) node!: any;
  @Prop({ required: true }) hits!: HitView[];
  @Prop({ required: false }) activeTab!: string;
  @Prop() containerIndex!: string;

  get containers(): any[] {
    if (Array.isArray(this.node)) return this.node;

    return [this.node];
  }

  pathForContainer(container: any) {
    if (Array.isArray(this.node)) {
      const index =
        "$id" in container
          ? `[?(@.$id == '${container.$id}')]`
          : `[${this.node.indexOf(container)}]`;

      return this.path + index;
    } else {
      return this.path;
    }
  }

  matchesForContainer(container: any) {
    let matches = [];

    matches = (this.hits || [])
      .flatMap(x => x.matches)
      .filter(x => x.field.startsWith(this.pathForContainer(container)));

    return matches;
  }
}
