




















import { Component, Prop, Vue } from 'vue-property-decorator';

import { CaseView } from '@/models/case-maintenance';
import { CaseStatus } from '@/models/case-status'
import { CaseStatusEntry } from '@/models/case-maintenance.d';
import UserControl from '@/components/user.vue';
import CaseHistoryItem from '@/components/case-history/case-history-item.vue';
import CaseHistoryStatusDetails from './case-history-status-details.vue';

@Component({
  components: {
    User: UserControl,
    CaseHistoryItem,
    CaseHistoryStatusDetails
  }
})
export default class CaseHistoryStatus extends Vue {
  @Prop({ required: true }) data!: CaseStatusEntry
  @Prop({ required: true }) model!: CaseView

  metadialogOpen = false;

  get isClosed() {
    return this.data.status === CaseStatus.Closed;
  }

  get colour() {
    return "red";
  }

  get icon() {
    return this.isClosed ? "mdi-door" : "mdi-state-machine";
  }
}
