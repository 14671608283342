





























































import {
  Vue, Component, Prop
} from 'vue-property-decorator';

import MapMarker from '@/components/material/map-marker.vue'
import RecursiveDetails from './recursive-details.vue';

@Component({
  components: {
    MapMarker,
    RecursiveDetails
  },
  inheritAttrs: false
})
export default class Sailings extends Vue {
  @Prop({ required: true }) booking: any;

  expanded: number | null = null;
  selectedStage: any | null = null;

  location(stage: any) {
    return stage.country?.code;
  }

  toggleSelectedStage(stage: object, index: number) {
    if (this.selectedStage === stage) {
      this.selectedStage = null;
      this.expanded = null;
    } else {
      this.selectedStage = stage;
      this.expanded = index;
    }
  }
}

