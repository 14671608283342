
















import { Component, Prop, Vue } from "vue-property-decorator";
import { Match } from "@/models/case-maintenance.d";
import Highlight from "@/components/common/highlight-matches.vue";

@Component({
  components: {
    Highlight
  }
})
export default class BookingDetailCargoDescriptionsComponent extends Vue {
  @Prop({ required: true }) cargo!: any;
  @Prop({ required: true }) matches!: Match[];
  @Prop({ required: true }) path!: string;

  hasCode(description: any) {
    return description.code;
  }

  pathForDescription(description: any, index: number): string {
    const indexer = "$id" in description ? `?(@.$id == '${description.$id}')` : index;
    return `${this.path}.description[${indexer}]`;
  }

  matchesForDescription(description: any, index: number): Match[] {
    return this.matches.filter(x =>
      x.field.startsWith(this.pathForDescription(description, index))
    );
  }
}
