




















































































import { CaseView } from '@/models/case-maintenance';
import {
  Vue, Component, Prop, Ref
} from 'vue-property-decorator';
import { EmailTemplate } from '@/models/email-templates';
import { SnackbarOptions } from '@/models/form';
import EcSnackBar from '@/components/common/ec-snackbar.vue';

@Component({
  components: {
    EcSnackBar
  }
})
export default class CaseEmailDropdown extends Vue {
  @Prop({ required: true }) model!: CaseView;
  @Prop({ required: true }) templates!: EmailTemplate[];
  @Prop({ required: true }) sendEmail!: (template: EmailTemplate, recipientEmail: string, message: string) => Promise<any>;

  sendEmailDialog = false;
  templateToSend?: EmailTemplate;
  recipientEmail = "";
  message = "";
  error = "";

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  @Ref() dialogForm!: HTMLFormElement;

  get disabled() {
    return !this.model;
  }

  openSendEmailDialog(template: EmailTemplate) {
    this.templateToSend = template;
    this.sendEmailDialog = true;
  }

  cancel() {
    this.reset();
  }

  sending = false;

  async send() {
    if (!this.dialogForm.validate() || ! this.templateToSend) return;
    this.sending = true;

    try {
      await this.sendEmail(this.templateToSend, this.recipientEmail, this.message);
      this.reset();
    } catch (e) {
      this.error = "Something went wrong sending the email."
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(this.error);
    } finally {
      this.sending = false;
    }
  }

  reset() {
    this.sendEmailDialog = false;
    this.templateToSend = undefined;
    this.recipientEmail = "";
    this.message = "";
    this.error = "";
    this.dialogForm.reset();
  }
}
