













import {
  Component, Prop, Vue
} from 'vue-property-decorator';

import BookingDetailContainers from './booking-detail-containers.vue';
import BookingDetailSailings from './booking-detail-sailings.vue';
import BookingDetailParties from './booking-detail-parties.vue';
import { HitView } from '@/models/case-maintenance';

import { JSONPath } from 'jsonpath-plus';

@Component
export default class BookingDetail extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) hits!: HitView[];
  @Prop({ required: false }) activeTab!: string;

  get node() {
    return JSONPath({ path: this.path, json: this.booking })[0];
  }

  get containerIndex() {
    const indexEtc = this.path.split("[");
    if (indexEtc.length === 1){
      return 0;
    }
    const indexString = indexEtc[1].split("]");
    const indexValue = parseInt(indexString[0]);
    return isNaN(indexValue) ? indexString[0] : indexValue + 1
  }

  get type() {
    if (this.path.startsWith("$.sailings")) return "sailings";
    if (this.path.startsWith("$.parties")) return "parties";
    if (this.path.startsWith("$.cargo-transport-units")) return "containers";
  }

  get component() {
    if (this.type === "sailings") return BookingDetailSailings;
    if (this.type === "parties") return BookingDetailParties;
    if (this.type === "containers") return BookingDetailContainers;
  }
}

