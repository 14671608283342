





























import { HitView } from "@/models/case-maintenance";
import { Vue, Component, Prop } from "vue-property-decorator";
import BookingDetailPartiesTitle from "@/components/booking/booking-detail-parties-title.vue";
import BookingDetailPartiesSubtitle from "@/components/booking/booking-detail-parties-subtitle.vue";

@Component({
  inheritAttrs: false,
  components: {
    BookingDetailPartiesTitle,
    BookingDetailPartiesSubtitle
  }
})
export default class BookingDetailParties extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) node!: any;
  @Prop({ required: true }) hits!: HitView[];

  get a() {
    return this.matchesForParties();
  }
  matchesForParties() {
    let matches = [];

    matches = (this.hits || [])
      .flatMap(x => x.matches)
      .filter(x => x.field.startsWith('$.parties'));

    return matches;
  }
}
