






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CaseHistoryNote extends Vue {
  @Prop({ required: true }) date!: string
}
