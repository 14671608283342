

















import { Match } from "@/models/case-maintenance.d";
import { Component, Prop, Vue } from "vue-property-decorator";
import BookingDetailCargoProperty from "./booking-detail-cargo-property.vue";

@Component({
  components: {
    BookingDetailCargoProperty
  }
})
export default class BookingDetailCargoPropertiesComponent extends Vue {
  @Prop({ required: true }) cargo!: any;
  @Prop({ required: true }) matches!: Match[];

  properties() {
    return Object.keys(this.cargo)
      .filter(x => x != "description")
      .filter(x => x[0] != "$")
      .filter(x => this.cargo[x]);
  }
}
