





































































































































































































import { Inject, Vue, Watch } from "vue-property-decorator";

import Component from "vue-class-component";

import EcSnackBar from "@/components/common/ec-snackbar.vue";
import Card from "@/components/material/Card.vue";
import CaseEmailDropdown from "@/components/case/case-email-dropdown.vue";
import CaseLinks from "@/components/case/case-links.vue";
import StatusControl from "@/components/status-control.vue";
import AssignmentControl from "@/components/assignment-control.vue";
import CaseNotes from "@/components/case/case-notes.vue";
import CaseHistory from "@/components/case-history/case-history.vue";
import CaseHits from "@/components/case/case-hits.vue";
import CloseCaseDialog from "@/components/case/close-case-dialog.vue";

import CaseService from "@/services/case-service";
import UserService from "@/services/user-service";
import ScreeningViewService from "@/services/screening-view-service";
import Notary from "@/services/notary";
import Booking from "@/components/booking/booking.vue";
import StatusService, { MetadataValuesModel } from "@/services/status-service";
import { User } from "@/models/case-maintenance.d";
import { CaseView, HitView, MatchView } from "@/models/case-maintenance";
import { CaseStatus } from "@/models/case-status";
import { SnackbarOptions } from "@/models/form";
import delay from "@/helpers/delay";
import { AxiosError } from "axios";
import EmailTemplateService from "@/services/email-template-service";
import { EmailTemplate } from "@/models/email-templates";
import ConfigurationService, { AllStatusesConfig } from "@/services/configuration-service";
import StatusConfigDialog from "@/components/configuration/status-config-dialog.vue";
import axios, { CancelTokenSource } from "axios";
import { HalResponse, HalPageInfo, PaginationOptions } from "@/models/hal";
import { CaseListOptions } from "@/models/session-state-types";

import { getModule } from "vuex-module-decorators";
import SessionState from "@/store/modules/session-state-module";
import BookingHitNavigationComponent from "@/components/booking/booking-hit-navigation.vue";
import CaseDetails from "@/components/case/case-details.vue";

import { caseTabs, getCaseDetails } from "@/views/Case";

const sessionState = getModule(SessionState);

type GotoCasePayload = {
  caseListPage: HalPageInfo["page"];
  cases: HalResponse<CaseView>[];
  caseListOptions: CaseListOptions;
};

@Component({
  components: {
    Card,
    EcSnackBar,
    CaseEmailDropdown,
    CaseLinks,
    AssignmentControl,
    StatusControl,
    CaseNotes,
    CaseHistory,
    CaseHits,
    Booking,
    CloseCaseDialog,
    StatusConfigDialog,
    BookingHitNavigationComponent,
    CaseDetails
  }
})
export default class CasePage extends Vue {
  @Inject() UserService!: UserService;
  @Inject() CaseService!: CaseService;
  @Inject() StatusService!: StatusService;
  @Inject() Notary!: Notary;
  @Inject() ScreeningViewService!: ScreeningViewService;
  @Inject() EmailTemplateService!: EmailTemplateService;
  @Inject() ConfigurationService!: ConfigurationService;

  screeningId: string | null = null;

  bookingNode: string[] = [];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  emailTemplates = [] as EmailTemplate[];

  loading = true;

  statusUpdateState = "default";
  statusError = "";

  assignmentUpdateState = "default";
  assignmentError = "";

  statusKey = 0;
  redrawStatusSelector() {
    this.statusKey++;
  }

  users: User[] = [];

  newNote = "";
  addNoteLoading = false;

  booking: any = {};
  bookingLoading = true;

  showStatusConfigDialog = false;
  statusMetadataConfig: AllStatusesConfig = { statuses: [] };
  newCaseStatus = -1;

  gotoMatchingCasesSearchTerm = "";
  cancelToken?: CancelTokenSource;
  lookingForMatchingCases = false;

  canGoBackToCasesList = true;

  activeCaseTab = 0;

  get caseTabs() {
    return caseTabs;
  }

  get caseModel(): CaseView {
    return sessionState.case;
  }

  get assignedUser(): string | null {
    return this.caseModel["assigned-to"];
  }

  get caseId(): string {
    return this.caseModel ? this.caseModel["case-id"] : "";
  }

  get currentHits(): HitView[] {
    return this.caseModel.hits.filter(x => x.isCurrent);
  }

  @Watch("$route", { immediate: true })
  routeChanges() {
    if (this.$route.query.path) {
      this.activeCaseTab = 1;
    }
  }

  @Watch("activeCaseTab")
  resetRoute() {
    const activeTab = caseTabs.find(tab => tab.id == this.activeCaseTab);
    if (activeTab?.name !== "Booking") {
      this.deselectHit();
      if (this.$route.query.path) {
        this.$router.replace({ query: {} });
      }
    }
  }

  get caseDetails() {
    return getCaseDetails(this.caseModel);
  }

  get caseDetailsLoaded() {
    return this.caseModel?.["case-details"]?.length > 0;
  }

  get hitsLoaded() {
    return this.caseModel?.hits?.length > 0;
  }

  async gotoMatchingCases() {
    this.lookingForMatchingCases = true;

    if (this.gotoMatchingCasesSearchTerm !== "") {
      const pagination = {
        page: 1,
        size: sessionState.caseListPage.size
      } as PaginationOptions;

      const gotoFilter = {
        key: "id",
        value: this.gotoMatchingCasesSearchTerm
      };
      const filter = [gotoFilter];

      const assignment = sessionState.caseListAssignment;

      if (this.cancelToken) this.cancelToken.cancel();
      this.cancelToken = axios.CancelToken.source();
      const list = await this.CaseService.listCases(
        pagination,
        filter,
        assignment,
        this.cancelToken.token
      );

      const overriddenCaseListOptions = sessionState.caseListOptions;
      overriddenCaseListOptions.filter = filter;
      if (list._embedded.cases.length > 0) {
        const gotoPayload: GotoCasePayload = {
          caseListPage: {
            number: 1,
            size: sessionState.caseListPage.size,
            totalElements: list.page.totalElements
          } as HalPageInfo["page"],
          cases: list._embedded.cases,
          caseListOptions: overriddenCaseListOptions
        };

        const firstCaseId = list._embedded.cases[0]["case-id"];
        const routeData = this.$router.resolve({ name: "case", params: { id: firstCaseId } });
        const newWindowRef = window.open(routeData.href, "_blank");

        newWindowRef?.sessionStorage.setItem("gotoPayload", JSON.stringify(gotoPayload));
      } else {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions("No case matches given case id");
      }
    }
    this.lookingForMatchingCases = false;
  }

  async mounted(): Promise<void> {
    this.loading = true;

    this.processGotoCasePayload();
    this.reloadCase(this.$route.params.id).then(() => this.loadBookingWithErrorHandling());
    this.loadUsers();
    this.loadEmailTemplates();
    this.statusMetadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  processGotoCasePayload() {
    const payloadJSON = window.sessionStorage.getItem("gotoPayload");
    if (payloadJSON) {
      this.canGoBackToCasesList = false;
      const payload = JSON.parse(payloadJSON ?? "{}");

      sessionState.setCaseListPage(payload["caseListPage"]);
      sessionState.setCases(payload["cases"]);
      sessionState.setCaseListOptions(payload["caseListOptions"]);
    }
  }

  get displayCaseNavigation() {
    return Boolean(sessionState.case && sessionState.cases.length > 1);
  }

  get atFirstCase() {
    return this.currentCaseNumber === 1;
  }

  get atLastCase() {
    return this.currentCaseNumber === this.caseListLength;
  }

  async goToCase(value: number) {
    const loadingTask = delay(200).then(
      () => ((this.loading = true), (this.bookingLoading = true))
    );

    this.resetCurrentCaseState();

    if (value > 0) await sessionState.nextCase();
    else await sessionState.previousCase();

    this.$router.replace({ name: "case", params: { id: sessionState.case["case-id"] } });

    await loadingTask;

    await this.loadBookingWithErrorHandling().then(
      () => ((this.loading = false), (this.bookingLoading = false))
    );
  }

  resetCurrentCaseState() {
    this.deselectHit();
    this.booking = null;
    this.statusUpdateState = "default";
    this.assignmentUpdateState = "default";
    this.assignmentError = "";
    this.statusError = "";
  }

  async loadBookingWithErrorHandling() {
    await this.loadBooking().catch(
      () =>
        (this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions("Unable to load booking data"))
    );
  }

  get currentCaseNumber() {
    return sessionState.casePosition;
  }

  get caseListLength() {
    return sessionState.caseListPage.totalElements;
  }

  async loadUsers() {
    const users = await this.UserService.listUsers({
      size: 100,
      sort: ["name,asc"]
    });

    this.users = users._embedded.users;
  }

  async loadEmailTemplates() {
    const templates = await this.EmailTemplateService.list();

    this.emailTemplates = templates._embedded["email-templates"].filter(x => !x["automated-only"]);
  }

  async refreshHistory() {
    await this.reloadCase();
  }

  async reloadCase(caseId?: string) {
    await sessionState.reloadCase(caseId);
    this.loading = false;
  }

  async loadBooking(): Promise<void> {
    this.screeningId = this.caseModel["screen-results"][0]["screening-id"];

    if (!this.screeningId) return Promise.reject("Screening Id not found in case model");
    this.bookingLoading = true;
    this.booking = await this.ScreeningViewService.readScreening(this.screeningId);
    this.bookingLoading = false;
  }

  changedHighlightedText(matchView: MatchView) {
    for (const hit of this.caseModel.hits) {
      for (const match of hit.matches) {
        match.singleHighlight = false;
      }

      const hitIndex = this.caseModel.hits.indexOf(hit);
      const filtered = hit.matches.indexOf(matchView);
      if (filtered === -1) continue;
      this.caseModel.hits[hitIndex].matches[filtered].singleHighlight = true;
    }
  }

  deselectHit() {
    this.caseModel.hits = this.caseModel.hits.map(hit => {
      return {
        ...hit,
        matches: hit.matches.map(match => {
          return { ...match, singleHighlight: false };
        })
      };
    });
  }

  get highlightSelected() {
    return (
      this.caseModel.hits?.filter(
        hit => hit.matches.filter(match => match.singleHighlight).length > 0
      ).length > 0
    );
  }

  getConfigTemplate(caseStatus: CaseStatus) {
    return this.statusMetadataConfig.statuses.filter(x => x.id === caseStatus)[0];
  }

  async onDropdownStatusChange(newStatus: CaseStatus) {
    this.statusUpdateState = "loading";

    this.newCaseStatus = newStatus;
    const metadataConfigForSelectedStatus = this.statusMetadataConfig.statuses.find(
      x => x.id === newStatus
    );
    if (
      metadataConfigForSelectedStatus?.metadata.length != 0 ||
      metadataConfigForSelectedStatus?.mandatoryNotes
    ) {
      this.showStatusConfigDialog = true;
    } else {
      const emptyMetadata: MetadataValuesModel = { metadata: [], note: "" };
      this.updateCaseStatus(newStatus, emptyMetadata);
    }
  }

  onRefresh() {
    this.loading = true;
    this.statusError = "";
    this.assignmentError = "";
    this.statusUpdateState = "default";
    this.assignmentUpdateState = "default";
    this.reloadCase();
  }

  onDialogUpdate(model: MetadataValuesModel) {
    this.updateCaseStatus(this.newCaseStatus, model);
    this.showStatusConfigDialog = false;
  }

  onDialogCancel() {
    this.statusUpdateState = "default";
    this.showStatusConfigDialog = false;
    this.redrawStatusSelector();
  }

  async updateCaseStatus(newStatus: CaseStatus, metadata: MetadataValuesModel) {
    if (!this.caseId) {
      return Promise.resolve();
    }

    this.statusUpdateState = "loading";

    try {
      await this.StatusService.setCaseStatus(this.caseModel, newStatus, metadata, {
        willHandle: () => true
      });

      sessionState.SetStatus({ caseId: this.caseId, status: newStatus });
      this.statusUpdateState = "success";
      await delay(2000);
      this.statusUpdateState = "default";
    } catch (thrown) {
      this.statusUpdateState = "error";

      if (typeof thrown === "string") {
        this.statusError = thrown;
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        this.statusError = "The case status has been modified.";
      } else {
        const message = error.message;
        this.statusError = message;
      }

      this.reloadCase();
    }
  }

  async updateAssignment(newUserId: string | null) {
    this.assignmentUpdateState = "loading";

    if (!this.caseId) {
      return Promise.resolve();
    }

    try {
      if (newUserId === null) {
        await this.UserService.unassignCase(this.caseModel, {
          willHandle: () => true
        });
      } else {
        await this.UserService.assignCaseToUser(this.caseModel, newUserId, {
          willHandle: () => true
        });
      }

      this.updateCaseAction(this.caseModel["case-id"], newUserId);
      this.assignmentUpdateState = "success";

      setTimeout(() => {
        this.assignmentUpdateState = "default";
      }, 2000);
    } catch (thrown) {
      this.assignmentUpdateState = "error";

      if (typeof thrown === "string") {
        this.assignmentError = thrown;
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        this.assignmentError = "The assignee has been modified.";
      } else {
        const message = error.message;
        this.assignmentError = message;
      }
      this.reloadCase();
    }
  }

  async addNote() {
    this.addNoteLoading = true;
    await this.Notary.addNoteToCase(this.caseId, this.newNote);
    await this.reloadCase();
    this.newNote = "";
    this.addNoteLoading = false;
  }

  sendEmail(template: EmailTemplate, recipientEmail: string, message: string) {
    return this.EmailTemplateService.sendCaseEmail(
      this.caseId,
      template.id,
      recipientEmail,
      message
    );
  }

  goToCaseList() {
    const query = sessionState.caseListQuery;
    this.$router.push({ name: "cases", query: query }).catch(() => {
      return true;
    });
  }

  closeTab() {
    window.close();
  }

  get shouldNotBeAbleToNavigateLeft() {
    return (
      this.atFirstCase ||
      this.statusUpdateState === "loading" ||
      this.assignmentUpdateState === "loading"
    );
  }
  get shouldNotBeAbleToNavigateRight() {
    return (
      this.atLastCase ||
      this.statusUpdateState === "loading" ||
      this.assignmentUpdateState === "loading"
    );
  }

  async updateCaseAction(caseId: string, newUserId: string | null) {
    sessionState.SetNewUserId({ caseId, newUserId });
  }
}
